import * as React from 'react';
import { Layout } from '../components/layout/PageSingle';
import { heading, paragraph, container } from './404.module.css';

export default function NotFoundPage() {
  return (
    <Layout>
      <div className={container}>
        <h1 className={heading}>404 - Seite nicht gefunden</h1>
        <p className={paragraph}>Sorry, aber wir konnten Ihre Seite leider nicht finden</p>
      </div>
    </Layout>
  );
}
